import { API_ADDRESS } from "../constants/general";
import { LOGIN, LOGOUT, GET_ENUMS } from "../constants/action-types";
import { ID_TOKEN } from "../constants/general";
import { authGet } from "../utils/fetching";
import { getCuarteles, getComandancias } from "./ubicaciones";
import { getMultas } from "./multas";

export function login(payload) {
  return function(dispatch) {
    return fetch(`${API_ADDRESS}/user_auth`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type':'application/json'
      },
      body: JSON.stringify(payload)
    }).then(response => response.json())
      .then(json => {
        if (json.exitoso){
          dispatch({ type: LOGIN, payload: json });
          // TODO: no permanent storing of JWT
          localStorage.setItem(ID_TOKEN, json.jwt);
        } else {
          throw "Log-In request failed";
        }
      })
      .then(() => dispatch(preLoadAll()));
  }
};

export function reloadInfo(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/mi_info`)
      .then(response => response.json())
      .then(json => {
        if (json.exitoso){
          return dispatch({ type: LOGIN, payload: json });
        } else {
          return dispatch(logout())
        }
      });
  }
};

export function logout(payload) {
  localStorage.removeItem(ID_TOKEN);
  return { type: LOGOUT, payload };
}

export function getEnums(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_enums`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_ENUMS, payload: {enums: json.enums} });
      }
    });
  }
};

export function preLoadAll(payload) {
  return function(dispatch) {
    return Promise.all([
      dispatch(reloadInfo()),
      dispatch(getCuarteles()),
      dispatch(getComandancias()),
      dispatch(getEnums()),
      dispatch(getMultas()),
    ])
  }
};
