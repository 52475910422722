import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';


const Dropdown = (props) => {
  const onChange = event => props.onChange({target: {id: props.id, value: event.target.value}})

  var choices = Object.keys(props.choices).map(function(key){
    return (
      <MenuItem key={key} value={props.choices[key].value}>{props.choices[key].label}</MenuItem>
    );
  }.bind(this));
  var selectProps = Object.assign({}, props);
  selectProps.onChange = onChange;
  delete selectProps.choices;
  selectProps.inputProps = Object.assign(
    {name: props.label,
     id: props.id,
    },
     selectProps.inputProps,
  )
  const inputLabel = <InputLabel htmlFor={props.id} shrink={true}>{props.label}</InputLabel>
  return (
    <FormControl fullWidth margin="dense">
      { props.label ? inputLabel : null}
      <Select
          {...selectProps}
          inputProps={{
            name: props.label,
            id: props.id,
          }}
        >
          {choices}
      </Select>
    </FormControl>
  )
}

export default Dropdown;
