import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { connect } from "react-redux";

import SquareInput from "./SquareInput";
import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { editMulta, getMultas } from "../actions/multas";
import { titleString, uuidv4 } from "../utils/misc";


const mapStateToProps = state => {
  
  return {
    isEditing: state.isAddingMulta,
    user: state.user,
    infraccion_aplicable_a: (state.enums.InfraccionAplicableA || []).map((x) => ({label: titleString(x), value: x})),
    aplica_a: (state.enums.AplicaA || []).map((x) => ({label: titleString(x), value: x})),
    tipo_de_infraccion: (state.enums.TipoInfraccion || []).map((x) => ({label: titleString(x), value: x})),
    vehiculo_marca: (state.enums.VehiculoMarca || []).map((x) => ({label: titleString(x), value: x})),
    vehiculo_color: (state.enums.VehiculoColor || []).map((x) => ({label: titleString(x), value: x})),
    marque_la_que_corresponda: (state.enums.MarqueCorresponda || []).map((x) => ({label: titleString(x), value: x})),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editMulta: (isEditing) => dispatch(editMulta(isEditing)),
    getMultas: () => dispatch(getMultas()),
  };
}

class ConnectedQuerellaEditor extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleGuardar = this.handleGuardar.bind(this);
    this.makeInput = this.makeInput.bind(this);

    this.state = {}
  }

  handleChange(event) {
    this.setState({ [event.target.id]: event.target.value });
  }

    handleGuardar(event) {
    let numero_multa = uuidv4();
    let payload = Object.assign({numero_multa: numero_multa}, this.state);
    authPost(`${API_ADDRESS}/create_multa`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        Promise.all([
          this.props.getMultas(),
          this.props.editMulta(false),
        ])
      }
    })
    .then(() => {
      window.open(
        `/multa?num=${numero_multa}&recibo=true`,
        "Imprimir Boleto"
      );
      // window.open( 
      //   `my.bluetoothprint.scheme://${API_ADDRESS}/external/get_multa_printer/${numero_multa}`,
      //   "Imprimir Boleto"
      // );
    })
  }

  makeInput(id, label, type, borderRight, borderBottom, readOnly, rows, choices) {
    console.log(id);
    return (
      <SquareInput
        id={id}
        label={label}
        value={this.state[id] || ""}
        onChange={this.handleChange}
        type={type}
        readOnly={readOnly}
        borderRight={borderRight}
        borderBottom={borderBottom}
        rows={rows || 1}
        choices={choices}
      />
    )
  }

  render() {
    const actions = (
      <div>
        <Button onClick={this.handleGuardar} color="primary">
          Someter
        </Button>
        <Button onClick={this.handleCancel} color="secondary">
          Cancelar
        </Button>
      </div>
    )

    return (
      <div>
        <Dialog maxWidth="lg" fullWidth open={this.props.isEditing} aria-labelledby="form-dialog-title" onClose={() => {this.props.editMulta(false)}}>
          <DialogTitle id="form-dialog-title" disableTypography>
            <Typography variant="h6">Boleto por Falta Administrativa</Typography>
            <IconButton
              aria-label="close"
              onClick={() => {this.props.editMulta(false)}}
              style={{position: "absolute", right: 2, top:2}}
              >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={0}>
              <Grid item md={3}>{this.makeInput("lugar_ocurrencia", "Lugar de Ocurrencia", "text", 0, 0)}</Grid>
              <Grid item md={3}>{this.makeInput("municipio", "Municipio", "text", 0, 0)}</Grid>
              <Grid item md={3}>{this.makeInput("infraccion_aplicable_a", "Aplicable A", "select", 0, 0, false, 1, this.props.infraccion_aplicable_a)}</Grid>
              <Grid item md={3}>{this.makeInput("tipo_de_infraccion", "Tipo de Infracción", "select", undefined, 0, false, 1, this.props.tipo_de_infraccion)}</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item md={3}>{this.makeInput("numero_registro", "Número de Registro", "text", 0, 0)}</Grid>
              <Grid item md={3}>{this.makeInput("vehiculo_tablilla", "Número de Tablilla", "text", 0, 0)}</Grid>
              <Grid item md={3}>{this.makeInput("numero_licencia_conductor", "Número de Licencia de Conductor", "text", 0, 0)}</Grid>
              <Grid item md={3}>{this.makeInput("numero_identificacion_compania", "Número de Identificación de Compañía", "text", undefined, 0)}</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item md={2}>{this.makeInput("vehiculo_marca", "Marca", "select", 0, 0, false, 1, this.props.vehiculo_marca)}</Grid>
              <Grid item md={2}>{this.makeInput("vehiculo_modelo", "Modelo", "text", 0, 0)}</Grid>
              <Grid item md={2}>{this.makeInput("vehiculo_ano", "Año", "number", 0, 0)}</Grid>
              <Grid item md={2}>{this.makeInput("vehiculo_color", "Color", "select", 0, 0, false, 1, this.props.vehiculo_color)}</Grid>
              <Grid item md={4}>{this.makeInput("vehiculo_vin", "Número de Identificación (VIN)", "text", undefined, 0)}</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid container spacing={0} md={4}>
                <Grid row md={12}>{this.makeInput("nombre_conductor", "Nombre", "text", 0, 0, false, 1)}</Grid>
                <Grid row md={12}>{this.makeInput("apellido_conductor", "Apellido", "text", 0, 0, false, 1)}</Grid>
              </Grid>
              <Grid item md={4}>{this.makeInput("direccion_conductor", "Dirección del Conductor", "text", 0, 0, false, 4)}</Grid>
              <Grid container spacing={0} md={2}>
                <Grid row md={12}>{this.makeInput("aplica_a", "Aplica A", "select", 0, 0, false, 1, this.props.aplica_a)}</Grid>
                <Grid row md={12}>{this.makeInput("puntos", "Puntos", "number", 0, 0, false, 1)}</Grid>
              </Grid>
              <Grid container spacing={0} md={2}>
                <Grid row md={12}>{this.makeInput("multa_a_pagar", "Multa a Pagar", "number", undefined, 0, false, 1)}</Grid>
                <Grid row md={12}>{this.makeInput("articulo_num", "Artículo Num.", "text", undefined, 0, false, 1)}</Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item md={4}>{this.makeInput("nombre_de_la_compania", "Nombre de la Compañía/Transporte Marítimo", "text", 0, 0)}</Grid>
              <Grid item md={4}>{this.makeInput("direccion_de_la_compania", "Dirección de la Compañía", "text", 0, 0)}</Grid>
              <Grid item md={2}>{this.makeInput("rotulo_velocidad", "Rótulo Velocidad KM", "check", 0, 0)}</Grid>
              <Grid item md={2}>{this.makeInput("rehuso_ver_lec_radar", "Rehusó Ver Lec. Radar", "check", undefined, 0)}</Grid>
            </Grid>
            <Grid container spacing={0}>
              <Grid item md={3}>{this.makeInput("nombre_del_destinatario", "Nombre del Destinatario", "text", 0, undefined)}</Grid>
              <Grid item md={3}>{this.makeInput("numero_de_control", "Número de control", "text", 0, undefined)}</Grid>
              <Grid item md={2}>{this.makeInput("marque_la_que_corresponda", "Marque la Correspondiente", "select", 0, undefined, false, 1, this.props.marque_la_que_corresponda)}</Grid>
              <Grid item md={2}>{this.makeInput("vio_lec_radar", "Vió Lec. Radar", "check", 0, undefined)}</Grid>
              <Grid item md={2}>{this.makeInput("estado_embriaguez", "Estado de Embriaguez", "check", undefined, undefined)}</Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            {actions}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const QuerellaEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedQuerellaEditor);

export default QuerellaEditor;
