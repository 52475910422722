import { LOGIN, LOGOUT, EDIT_QUERELLA, EDIT_UBICACIONES } from "../constants/action-types";

const initialState = {
  user: null,
  isEditingQuerella: false,
  isEditingUbicaciones: false,
  numQuerellaEditing: null,
  sectores: [],
  cuarteles: [],
  comandancias: [],
  enums: {},
  patrullas: [],
};

function rootReducer(state = initialState, action) {
  if (action.type === LOGIN) {
    return Object.assign({}, state, {
      user: action.payload
    });
  }
  if (action.type === LOGOUT) {
    return Object.assign({}, state, {user: null});
  }
  return Object.assign({}, state, action.payload);
};

export default rootReducer;
