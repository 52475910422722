import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import PaginaPolicia from './PaginaPolicia';
import PaginaError from './PaginaError';
import PaginaPago from './PaginaPago';
 
class App extends Component {
  render() {
    return (      
       <BrowserRouter>
        <div>
        <Switch>
            <Route path="/" component={PaginaPolicia} exact/>
            <Route path="/multa" component={PaginaPago} />
            <Route component={PaginaError}/>
        </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;