export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const FULL_UPDATE = "FULL_UPDATE";
export const EDIT_UBICACIONES = "EDIT_UBICACIONES";
export const EDIT_USUARIOS = "EDIT_USUARIOS";
export const GET_SECTORES = "GET_SECTORES";
export const GET_CUARTELES = "GET_CUARTELES";
export const GET_COMANDANCIAS = "GET_COMANDANCIAS";
export const GET_ENUMS = "GET_ENUMS";
export const EDIT_PATRULLAS = "EDIT_PATRULLAS";
export const GET_PATRULLAS = "GET_PATRULLAS";
