import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from "react-redux";

import Drawer from './Drawer';



const mapStateToProps = state => {
  return { user: state.user };
};

const ConnectedNavbar = ({ user }) => {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  return (
    <div style={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" color="secondary" style={{ flexGrow: 1 }}>
            UnoCero&nbsp;
          </Typography>

          {user !== null && <Typography>{user.placa} {user.apellido}</Typography>}
          <IconButton color="secondary" aria-label="Main Menu" onClick={() => setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
        <Drawer open={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </AppBar>
    </div>
  );
};

const Navbar = connect(mapStateToProps, null)(ConnectedNavbar);
export default Navbar;
