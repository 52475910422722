import React, { Component } from 'react';
import {  ThemeProvider } from '@material-ui/core/styles';
import { connect } from "react-redux";

import { THEME, APPBAR_THEME } from '../constants/theme'
import Navbar from './Navbar';
import LoginWindow from './LoginWindow';
import QuerellaWindow from './QuerellaWindow';
import { preLoadAll } from "../actions/index";




const mapStateToProps = state => {
  return { isLoggedIn: state.user !== null };
};

function mapDispatchToProps(dispatch) {
  return {reloadInfo: () => dispatch(preLoadAll())};
}

class ConnectedPaginaPolicia extends Component {
  constructor(props) {
    super(props);
    this.props.reloadInfo();
  }

  render() {
    return (
      <div>
        <ThemeProvider theme={APPBAR_THEME}>
          <Navbar />
        </ThemeProvider>
        <ThemeProvider theme={THEME}>
          { this.props.isLoggedIn ? <QuerellaWindow /> : <LoginWindow /> }
        </ThemeProvider>
      </div>
    );
  }
}

const PaginaPolicia = connect(mapStateToProps, mapDispatchToProps)(ConnectedPaginaPolicia);
export default PaginaPolicia;
