import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';



const Suggest = (props) => {
  const onChange = (event, newValue) => props.onChange({target: {id: props.id, value: newValue}});

  var choices = props.choices.reduce((obj, item) => {
    return {
      ...obj,
      [item.value]: item.label,
    };
  }, {});

  return (
    <Autocomplete
      id={props.id}
      options={Object.keys(choices)}
      freeSolo
      value={props.value}
      inputValue={props.value}
      getOptionLabel={option => (choices[option] || option)}
      onChange={onChange}
      renderInput={params => (
        <TextField
          {...params}
          label={props.label}
          onChange={props.onChange}
          margin="dense"
          fullWidth
          InputLabelProps={{ ...props.InputLabelProps}}
          InputProps={{...params.InputProps, ...props.InputProps}}
        />
      )}
    />
  )
}

export default Suggest;
