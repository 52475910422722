import React, { Component } from 'react';
import { connect } from "react-redux";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Dropdown from "./Dropdown";
import { ComandanciaTable, CuartelTable } from './TablasUbicaciones';

import { editUbicaciones, addCuartel, addComandancia, rmCuartel, rmComandancia } from "../actions/ubicaciones";



const mapStateToProps = state => {return {isEditing: state.isEditingUbicaciones};};

function mapDispatchToProps(dispatch) {
  return {editUbicaciones: (isEditing) => dispatch(editUbicaciones(isEditing))};
}

const mapDataFieldToProps = field => state => {
  return {
    data: state[field],
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    comandancias: state.comandancias.map((o) => ({label: o.nombre, value: o.numero_comandancia})),
  };
};

const mapDispatchFieldToProps = (onRowAdd, onRowDelete) => dispatch => {
  return {
    editable: {
      onRowAdd: (newValue) => dispatch(onRowAdd(newValue)),
      onRowDelete: (oldValue) => dispatch(onRowDelete(oldValue)),
    }
  };
}

const Comandancias = connect(
  mapDataFieldToProps("comandancias"),
  mapDispatchFieldToProps(addComandancia, rmComandancia)
)(ComandanciaTable);

const Cuarteles = connect(
  mapDataFieldToProps("cuarteles"),
  mapDispatchFieldToProps(addCuartel, rmCuartel)
)(CuartelTable);

const ubicacionTables = [
  <Comandancias />,
  <Cuarteles />,
]

class ConnectedUbicacionEditor extends Component {
  constructor(props) {
    super(props);
    this.handleTab = this.handleTab.bind(this);
    this.state={currentTab: 0};
  }

  handleTab(event, newValue) {
    this.setState({ currentTab: newValue });
  }

  render() {
    return (
      <div>
        <Dialog maxWidth="lg" fullWidth open={this.props.isEditing} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Editar Ubicaciones</DialogTitle>
          <DialogContent>
            <Tabs value={this.state.currentTab} onChange={this.handleTab}>
              <Tab label="Comandancias" />
              <Tab label="Cuarteles" />
            </Tabs>
            <br />
            <br />
            {ubicacionTables[this.state.currentTab]}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {this.props.editUbicaciones(false)}} color="primary">
              Terminar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const UbicacionEditor = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUbicacionEditor);

export default UbicacionEditor;
