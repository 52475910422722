import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import PhoneNumberMask from './PhoneNumberMask';

import Suggest from './Suggest';


const SquareInput = (props) => {
  const handleForSelect = event => props.onChange({target: {id: props.id, value: event.target.value}});
  const handleForCheck = event => props.onChange({target: {id: props.id, value: event.target.checked}});
  const handleForSuggest = (event, newValue) => props.onChange({target: {id: props.id, value: newValue}});

  if (props.type === "select") {
    var choices = Object.keys(props.choices).map(function(key){
      return (
        <MenuItem key={key} value={props.choices[key].value}>{props.choices[key].label}</MenuItem>
      );
    }.bind(this));
    var input = (
      <FormControl fullWidth margin="dense">
      <InputLabel htmlFor={props.id} shrink={true}>{props.label}</InputLabel>
      <Select
          value={props.value}
          onChange={handleForSelect}
          style={{ borderColor: null }}
          //variant="outlined"
          inputProps={{
            name: props.label,
            id: props.id,
          }}
          disableUnderline={true}
        >
          {choices}
        </Select>
        </FormControl>
    )
  } else if (props.type === "suggest") {
    var input = (
      <Suggest
        id={props.id}
        value={props.value}
        choices={props.choices}
        onChange={props.onChange}
        InputLabelProps={{ shrink: true }}
        InputProps={{disableUnderline: true, readOnly: props.readOnly || false}}
      />
    );
  } else if (props.type === "phone") {
    var input = (
      <FormControl fullWidth margin="dense">
        <InputLabel htmlFor={props.id}>{props.label}</InputLabel>
        <Input
          value={props.value}
          onChange={props.onChange}
          id={props.id}
          inputComponent={PhoneNumberMask}
          disableUnderline={true}
        />
      </FormControl>
    )
  } else if (props.type === "check"){
    var input = (
      <Box mt={1} mb={.5}>
        <InputLabel htmlFor={props.id} shrink={true}>{props.label}</InputLabel>
        <Checkbox
          checked={props.value}
          disableRipple
          id={props.id}
          onChange={handleForCheck}
          color="primary"
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
        />
      </Box>
    );
  }else {
    var input = (
      <TextField
        margin="dense"
        id={props.id}
        label={props.label}
        value={props.value}
        onChange={props.onChange}
        type={props.type}
        fullWidth
        InputLabelProps={{ shrink: true }}
        InputProps={{disableUnderline: true, readOnly: props.readOnly || false}}
        multiline={(props.rows || 1) > 1}
        rows={props.rows || 1}
      />
    )
  }
  return (
    <Box
      border={1}
      borderRight={props.borderRight}
      borderLeft={props.borderLeft}
      borderTop={props.borderTop}
      borderBottom={props.borderBottom}
      pl="2px"
      pr="2px"
      borderColor="grey.500"
      flexGrow={1}
      style={{ height: "100%"}}
    >
      {input}
    </Box>
  );
}



export default SquareInput;
