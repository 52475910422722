import React, { Component } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';

import { connect } from "react-redux";

import SquareInput from "./SquareInput";
import { API_ADDRESS } from "../constants/general";
import { authPost } from "../utils/fetching";
import { editMulta, getMultas } from "../actions/multas";
import { titleString } from "../utils/misc";


const camposGenerales = [
  'numero_multa', 'fecha_expedida_multa', 'municipio',
  'lugar_ocurrencia', 'patrullero', 'infraccion_aplicable_a',
  'tipo_de_infraccion'
]
  
const camposVehiculo = [
  'numero_registro', 'vehiculo_tablilla', 'numero_licencia_conductor',
  'numero_identificacion_compania', 'nombre_conductor', 'apellido_conductor', 'direccion_conductor',
  'vehiculo_modelo', 'vehiculo_marca', 'vehiculo_ano', 'vehiculo_color',
  'vehiculo_vin', 'nombre_de_la_compania', 'direccion_de_la_compania',
  'nombre_del_destinatario',
]

const camposMulta = [
  'aplica_a', 'puntos', 'multa_a_pagar',
  'articulo_num',  'rotulo_velocidad',
  'rehuso_ver_lec_radar', 'vio_lec_radar', 'estado_embriaguez',
  'numero_de_control', 'marque_la_que_corresponda',
]

function makeListItem(key, infoObj) {
  var value = infoObj[key];
  if (infoObj[key] == null) {
    return null;
  } else if (typeof(infoObj[key]) === 'string') {
    value = titleString(value)
  }
  return (
    <ListItem dense>
      <ListItemText primary={titleString(key)} secondary={value} />
    </ ListItem>
  );
}

class MultaDisplay extends Component {
  render() {

    const generales = camposGenerales.map(x => makeListItem(x, this.props.info));
    const vehiculo = camposVehiculo.map(x => makeListItem(x, this.props.info));
    const multa = camposMulta.map(x => makeListItem(x, this.props.info));

    return (
      <List disablePadding dense>
        {generales}
        <Divider />
        {vehiculo}
        <Divider />
        {multa}
      </List>
    );
  }
}


export default MultaDisplay;
