import React from 'react';

import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import { connect } from "react-redux";


import { logout } from "../actions/index";
import { editUbicaciones } from "../actions/ubicaciones";
import { editUsuarios } from "../actions/usuarios";


function mapStateToProps(state) {
  return { user: state.user };
};

function mapDispatchToProps(dispatch) {
  return {
    logout: () => dispatch(logout()),
    editUbicaciones: (isEditing) => dispatch(editUbicaciones(isEditing)),
    editUsuarios: (isEditing) => dispatch(editUsuarios(isEditing)),
  };
}

const ConnectedDrawer = ({ open, setDrawerOpen, logout, editUbicaciones, editUsuarios, user}) => {

  const adminActions = (
    <List>
      <ListItem button onClick={() => editUbicaciones(true)}>
        <ListItemIcon> <AddLocationIcon /></ListItemIcon>
        <ListItemText primary="Ubicaciones" />
      </ListItem>
      <ListItem button onClick={() => editUsuarios(true)}>
        <ListItemIcon> <PersonAddIcon /></ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItem>
    </List>
  )

  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => setDrawerOpen(false)}
      onOpen={() => setDrawerOpen(true)}
    >
      <div
        role="presentation"
        onClick={() => setDrawerOpen(false)}
        onKeyDown={() => setDrawerOpen(false)}
      >
        {user !== null && user.is_superuser && adminActions}
        <Divider />
        <List>
          <ListItem button onClick={logout}>
            <ListItemIcon> <ExitToAppIcon /></ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </div>
    </SwipeableDrawer>
  );
}

const Drawer = connect(mapStateToProps, mapDispatchToProps)(ConnectedDrawer);
export default Drawer;
