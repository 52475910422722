import React, { Component } from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';

import Dropdown from "./Dropdown";
import InteractiveTable from "./InteractiveTable";



export const ComandanciaTable = (pProps) => (
  <InteractiveTable
    title="Comandancias"
    label="Comandancias"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {title: "Número", field: "numero_comandancia"}
    ]}
  />
);


export const CuartelTable = (pProps) => (
  <InteractiveTable
    title="Cuarteles"
    label="Cuarteles"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {title: "Número", field: "numero_cuartel"},
      {
        title: "Comandancia",
        field: "numero_comandancia",
        editComponent: props => (
          <Dropdown
            choices={pProps.comandancias}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        render: rowData => {
          let obj = pProps.comandancias.find(o => o.value == rowData.numero_comandancia);
          return <Typography>{obj ? obj.label : "N/A"}</Typography>;
        }
      },
    ]}
  />
);

export const SectorTable = (pProps) => (
  <InteractiveTable
    title="Sectores"
    label="Sectores"
    {...pProps}
    columns={[
      {title: "Nombre", field: "nombre"},
      {
        title: "Cuartel",
        field: "numero_cuartel",
        editComponent: props => (
          <Dropdown
            choices={pProps.cuarteles}
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
          />
        ),
        render: rowData => {
          let obj = pProps.cuarteles.find(o => o.value == rowData.numero_cuartel);
          return <Typography>{obj ? obj.label : "N/A"}</Typography>;
        }
      },
      {title: "Código", field: "codigo"}
    ]}
  />
);
