import React, { Component } from 'react';
import { connect } from "react-redux";

import Typography from '@material-ui/core/Typography';
import InteractiveTable from './InteractiveTable';
import AddIcon from '@material-ui/icons/Add';

import Dropdown from "./Dropdown";
import Suggest from "./Suggest";
import { editMulta } from "../actions/multas";
import { titleString } from "../utils/misc";



const mapStateToProps = state => {
  var patrulleros = [];
  if (state.user.division != 'centro_de_mando') {
    patrulleros = [{label: `${state.user.placa} ${state.user.apellido}`, value: state.user.placa}]
  } else {
    patrulleros = (state.patrulleros || []).map((patrullero) => ({label: `${patrullero.placa} ${patrullero.apellido}`, value: patrullero.placa}))
  }

  return {
    multas: state.multas ? state.multas : [],
    user: state.user,
    cuarteles: state.cuarteles.map((cuartel) => ({label: cuartel.nombre, value: cuartel.numero_cuartel})),
    patrulleros: patrulleros,
    EstadosMulta: (state.enums.EstadoMulta || []).map((x) => ({label: titleString(x), value: x})),
    TipoInfraccion: (state.enums.TipoInfraccion || []).map((x) => ({label: titleString(x), value: x})),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    editMulta: () => dispatch(editMulta(true)),
  };
}

class ConnectedTableMultas extends Component {
  constructor(props) {
    super(props);
    this.state={
      isOpen: false,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal(event) {
    this.setState({isOpen: !this.state.isOpen});
  }

  render () {
    const cuarteles = this.props.cuarteles;
    const tipoInfraccion = this.props.TipoInfraccion;
    const patrulleros = this.props.patrulleros;

    return (
      <div>
        <InteractiveTable
          title="Multas"
          label="Multas"
          data={this.props.multas}
          actions={[
            {
              icon: () => (<AddIcon />),
              tooltip: 'Crear Multa',
              onClick: this.props.editMulta,
              isFreeAction: true,
            },
          ]}
          columns={[
            {title: "Estado", field: "estado", editable: 'never',
             render: rowData => (<Typography>{rowData ? titleString(rowData.estado) : "N/A"} </Typography>),
            },
            {
              title: "Cuartel", field: "numero_cuartel",
              render: rowData => {
                let obj = this.props.cuarteles.find(o => o.value == rowData.numero_cuartel);
                return <Typography>{obj ? obj.label : "N/A"}</Typography>;
              },
            },
            {
              title: "Tipo de Infracción", field: "tipo_de_infraccion",
              render: rowData => (<Typography>{titleString((rowData && rowData.tipo_de_infraccion) ? rowData.tipo_de_infraccion : "N/A")} </Typography>),
            },
            {title: "Lugar de Ocurrencia", field: "lugar_ocurrencia"},
            {title: "Patrullero", field: "patrullero"}
          ]}
        />
        </div>
      )
    }
};


const TablaMultas = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTableMultas);

export default TablaMultas;
