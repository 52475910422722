import { ID_TOKEN } from "../constants/general";

export function authGet(url) {
  // TODO: get from redux store or cookie
  const jwt = localStorage.getItem(ID_TOKEN);
  return fetch(url, {
    headers: {Authorization: `Bearer ${jwt}`}
  });
}

export function authPost(url, data) {
  // TODO: get from redux store or cookie
  const jwt = localStorage.getItem(ID_TOKEN);
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
  headers['Authorization'] = `Bearer ${jwt}`

  return fetch(url, {
    headers,
    method: 'POST',
    body: JSON.stringify(data)
  })
}
