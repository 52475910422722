import React from 'react';
import Typography from '@material-ui/core/Typography';
import { connect } from "react-redux";

import QuerellaEditor from './QuerellaEditor';
import TablaQuerellas from './TablaQuerellas';
import UbicacionEditor from './UbicacionEditor';
import UsuarioEditor from './UsuarioEditor';

const mapStateToProps = state => {
  return { user: state.user };
};

const ConnectedQuerellaWindow = ({ user }) => (
  <div style={{ flexGrow: 1 }}>
    <TablaQuerellas />
    <QuerellaEditor />
    <UbicacionEditor />
    <UsuarioEditor />
  </div>
);

const QuerellaWindow = connect(mapStateToProps)(ConnectedQuerellaWindow);
export default QuerellaWindow;
