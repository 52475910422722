import { API_ADDRESS } from "../constants/general";
import { EDIT_UBICACIONES, GET_SECTORES, GET_CUARTELES, GET_COMANDANCIAS } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function editUbicaciones(isEditing) {
  return {
    type: EDIT_UBICACIONES,
    payload: {isEditingUbicaciones: isEditing},
  };
}

export function addCuartel(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/new_cuartel`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        dispatch(getCuarteles());
      }
    })
  }
}

export function addComandancia(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/new_comandancia`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        dispatch(getComandancias());
      }
    })
  }
}

export function rmComandancia(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/remover_comandancia`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        dispatch(getComandancias());
      }
    })
  }
}

export function rmCuartel(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/remover_cuartel`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        dispatch(getCuarteles());
      }
    })
  }
}

export function getCuarteles(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_cuarteles`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_CUARTELES, payload: {cuarteles: json.cuarteles} });
      }
    });
  }
};

export function getComandancias(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_comandancias`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: GET_COMANDANCIAS, payload: {comandancias: json.comandancias} });
      }
    });
  }
};
