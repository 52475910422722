import { API_ADDRESS } from "../constants/general";
import { EDIT_USUARIOS } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function editUsuarios(isEditing) {
  return {
    type: EDIT_USUARIOS,
    payload: {isEditingUsuarios: isEditing},
  };
}
