import React, { useState, useEffect } from 'react';
import {  ThemeProvider } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { THEME, APPBAR_THEME } from '../constants/theme';
import QRCode from 'qrcode';
import { loadStripe } from '@stripe/stripe-js';

import paid from '../img/paid2.png';

import { API_ADDRESS } from "../constants/general";
import MultaDisplay from './MultaDisplay';
import { authPost } from "../utils/fetching";

const stripePromise = loadStripe('pk_test_51GrWY1K9NYnwrfBzNgCMwHUouVlYHP0bVLDj8LpUDQDlibNpIytXjZV5ZveLY9kvKOvyPfXMuzxMdn7A1CFFNjLH007wJNdDLi');

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function getInfo(numero_multa, settingFunc) {

  let infoPago = {
    env: 'production',
    publicToken: '4f8a8013de2476c9e156409e2d2e79ff064fc098',
    timeout: 600,
    theme: 'btn',
    lang: 'es',
    onCompletedPayment: function (response) {
      authPost(`${API_ADDRESS}/verify_athmovil`, {reference_number: response.referenceNumber})
        .then(response => response.json())
      console.log("Payment completed");
    },
    onCancelledPayment: function (response)
		{
			console.log("Payment cancelled");
		},
  }

  authPost(`${API_ADDRESS}/get_multa_info`, {numero_multa: numero_multa})
  .then(response => response.json())
  .then(json => {
    if (json.exitoso) {
        let info = json.info;
        settingFunc(info);
        infoPago.total = info.multa_a_pagar;
        infoPago.tax = 0;
        infoPago.subtotal = info.multa_a_pagar;
        infoPago.items = [{
            "name": info.tipo_de_infraccion,
            "description": "Boleto por falta administrativa",
            "quantity": "1",
            "price": info.multa_a_pagar,
            "tax": "0.00"
        }]
    
        window.ATHM_Checkout = infoPago;
        const script = document.createElement("script");
        script.src = "https://www.athmovil.com/api/js/v2/athmovilV2.js";
        document.body.appendChild(script);
    }
    
  })
}


export default function PaginaPago() {

  const [info, setInfo] = useState({});
  const [qr, setQR] = useState('');
  const isPaid = info.estado === 'paga';

  let query = useQuery();
  var multa_num = query.get("num");
  var recibo = false;
  recibo = query.get("recibo") === "true";

  const pagoStripe = async () => {
    const { session_id } = await fetch(`${API_ADDRESS}/stripe_payment?num=${multa_num}`).then((resp)=> resp.json())
    // When the customer clicks on the button, redirect them to Checkout.
    console.log(session_id);
    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId: session_id,
    });
  }

  useEffect(()=> {
    getInfo(multa_num, setInfo);
  }, [])

  QRCode.toDataURL(`${window.location.hostname}/multa?num=${multa_num}`)
    .then(url => {
        setQR(url);
    })
    .catch(err => {
        console.error(err)
    })

  const pagoComponent = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <img src={qr} align="center" />
      <Typography align="center">
        Para un pago rápido y eficiente apunte la cámara de su celular y siga el enlace.
      </Typography>
      <a href={`my.bluetoothprint.scheme://${API_ADDRESS}/get_multa_printer/${multa_num}`}>Imprimir</a>
    </ Grid>
    )

  const paidComponent = (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
    >
      <img src={paid} align="center" />
    </ Grid>
    )

  return (
    <ThemeProvider theme={THEME}>
      <Grid container justify="center">
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={4}>
          <Container>
              {isPaid && paidComponent }
              <Typography variant="h5" align="center" >
              Boleto por Falta Administrativa
              </Typography>
              <Box mt={3} />
              
              <MultaDisplay info={info} />
              <Box mb={3} />
          </Container>
          {!recibo && !isPaid && <div id="ATHMovil_Checkout_Button" style={{minHeight:"unset", boxShadow: "unset"}}></div>}
          {!recibo && !isPaid && <Typography align="center">o</Typography>}
          {!recibo && !isPaid && <Button onClick={pagoStripe} variant="contained" color="secondary" fullWidth size="large" component="span" style={{textTransform: "none"}}>Paga con Tarjeta de Crédito</Button>}
          {qr && recibo && pagoComponent}
          {isPaid && paidComponent}
        </ Grid>
        <Grid item xs={12} sm={4}></Grid>
      </ Grid>
    </ ThemeProvider>
  );
}
