import { API_ADDRESS } from "../constants/general";
import { FULL_UPDATE } from "../constants/action-types";
import { authGet, authPost } from "../utils/fetching";


export function getMultas(payload) {
  return function(dispatch) {
    return authGet(`${API_ADDRESS}/get_multas`)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso){
        return dispatch({ type: FULL_UPDATE, payload: {multas: json.multas} });
      }
    });
  }
}

export function editMulta(isEditing) {
  return {
    type: FULL_UPDATE,
    payload: {isAddingMulta: isEditing},
  };
}

export function addMulta(payload) {
  return function(dispatch) {
    return authPost(`${API_ADDRESS}/create_multa`, payload)
    .then(response => response.json())
    .then(json => {
      if (json.exitoso) {
        return dispatch(getMultas())
      }
    })
  }
}
